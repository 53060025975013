import React from "react";

const Articles = ({ classicHeader, darkTheme }) => {
  const articles = [
    {
      yearRange: "October 2024",
      title: "Backend Dev: Blowing The Dust Off",
      place: "Medium",
      desc: "Summary of me and my plans to 'dev-in-the-open' to learn and refresh knowledge",
      url: "https://medium.com/@cwolfs/blow-the-dust-off-my-backend-aef169268822",
    },
    {
      yearRange: "May 2014",
      title: "Game UI: Scalability & Aspect Ratios",
      place: "Rogue Vector's Blog",
      desc: "How to scale a game's UI and how to handle different aspect ratios.",
      url: "https://www.roguevector.com/interfacing-with-ui-2-scalability-aspect-ratios/",
    },
    {
      yearRange: "April 2014",
      title: "Game UI: Structure and Libraries",
      place: "Rogue Vector's Blog",
      desc: "How to structure a game's UI and the libraries that can be used to do so.",
      url: "https://www.roguevector.com/interfacing-with-ui-1-structure-and-libraries/",
    },
  ];

  const articles2 = [
    {
      yearRange: "February 2016",
      title: "UI Library Review: Coherent UI",
      place: "Rogue Vector's Blog",
      desc: "Coherent UI library and how it can be used to create a game's UI.",
      url: "https://www.roguevector.com/interfacing-with-ui-4-coherent-ui/",
    },
    {
      yearRange: "December 2014",
      title: "Embedding Lua into C++",
      place: "Rogue Vector's Blog",
      desc: "How to embed Lua into C++ and details on bindings.",
      url: "https://www.roguevector.com/mod-support-2-embedding-lua-into-c/",
    },
    {
      yearRange: "June 2014",
      title: "Game Scripting Languages",
      place: "Rogue Vector's Blog",
      desc: "Scripting languages and their uses in game development.",
      url: "https://www.roguevector.com/mod-support-1-scripting-languages/",
    },
  ];

  return (
    <section
      id="articles"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Articles
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            What I've Written
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* Articles */}
          <div className="col-md-6">
            {/* <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              Articles
            </h2> */}
            {articles.length > 0 &&
              articles.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-dark-1 text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <a href={value.url} target="_blank" rel="noreferrer">
                    <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                      {value.title}
                    </h3>
                  </a>
                  <a href={value.url} target="_blank" rel="noreferrer">
                    <p className={darkTheme ? "text-primary" : "text-danger"}>
                      {value.place}
                    </p>
                  </a>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
          {/* Articles 2 */}
          <div className="col-md-6">
            {/* <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              Articles 2
            </h2> */}
            {articles2.length > 0 &&
              articles2.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-dark-1 text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <a href={value.url} target="_blank" rel="noreferrer">
                    <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                      {value.title}
                    </h3>
                  </a>
                  <a href={value.url} target="_blank" rel="noreferrer">
                    <p className={darkTheme ? "text-primary" : "text-danger"}>
                      {value.place}
                    </p>
                  </a>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Articles;
