import React from "react";
// import WordAnimator from "./WordAnimator/WordAnimator";
// import videobg from "../videos/home.mp4";

import "./Home.scss";

const Home = ({ classicHeader, darkTheme, handleNavClick }) => {
  return (
    <section id="home" className="home">
      <div className="hero-wrap">
        <div className="hero-mask opacity-8 bg-dark" />

        {/* ---------------image background------------------ */}
        <div
          className="hero-bg parallax"
          style={{ backgroundImage: 'url("images/intro-bg-v3.jpeg")' }}
        ></div>

        {/* -------------------video background---------------------- */}

        {/* <div className="player hero-bg parallax">
          <video
            src={videobg}
            autoPlay
            muted
            loop
            style={{ width: "100%", height: "100vh", objectFit: "cover" }}
          ></video>
        </div> */}

        <div className="hero-content section d-flex">
          <div className="container my-auto">
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="text-16 fw-600 text-white mb-4 mb-md-4">
                  <span>Richard Griffiths</span>
                  {/* <WordAnimator /> */}
                </h2>
                <h3 className="text-8 text-white">Lead Developer</h3>
                <p className="text-4 text-light mb-4">
                  A British 🇬🇧 / German 🇩🇪 developer based in Frankfurt, Germany
                  🇩🇪
                </p>

                <div className="hero-buttons">
                  <a
                    href="#contact"
                    className="btn btn-outline-primary rounded-pill shadow-none smooth-scroll mt-2 portfolio-button"
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavClick("portfolio");
                    }}
                  >
                    Portfolio
                  </a>
                  <a
                    href="#contact"
                    className="btn btn-outline-primary rounded-pill shadow-none smooth-scroll mt-2"
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavClick("contact");
                    }}
                  >
                    Hire Me
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <a
            href="#about"
            className="scroll-down-arrow text-white smooth-scroll"
            onClick={(e) => {
              e.preventDefault();
              handleNavClick("about");
            }}
          >
            <span className="animated">
              <i className="fa fa-chevron-down" />
            </span>
          </a> */}
        </div>
      </div>
    </section>
  );
};

export default Home;
