import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    WEB: "Web",
    APPLICATION: "Application",
    MOBILE: "Mobile",
    GAME: "Game",
    BUSINESS: "Business",
  };

  const projectsData = [
    {
      title: "Esports Live Streaming & Events Platform",
      projectInfo:
        "Solo developed the entire frontend for Streams+, an esports and game streaming platform, enabling HAIA to expand into the gaming industry. Architected & developed the frontend driving market expansion whilst achieving cost reductions through efficient caching. Implemented multi-tenant theming to reuse components and significantly reduced future development time and costs. Delivered HAIA's vision for Streams+ within just 9 months.",
      client: "HAIA / Streams+",
      technologies: "React, Typescript, Tanstack Query, Zustand, Sass",
      industry: "Gaming Esports & Entertainment",
      date: "2024",
      url: {
        name: "Streams+",
        link: "https://www.streams.plus",
      },
      thumbImage: "images/projects/haia/streams/haia-streams2.png",
      sliderImages: [
        "images/projects/haia/streams/haia-streams1.png",
        "images/projects/haia/streams/haia-streams3.png",
        "images/projects/haia/streams/haia-streams4.png",
      ],
      categories: [filters.WEB, filters.BUSINESS, filters.GAME],
    },
    {
      title: "Image Data Tooling",
      projectInfo:
        "The work involved two NDA-restricted projects. I was hired for developing web tools & apps specialising in computer vision, I managed and developed the projects from start to finish, maintaining Apple's quality standards. This involved cross compiling libraries, microfrontend development, along with plugin and React app development.",
      client: "Apple",
      technologies:
        "React, Typescript, Redux, OpenCV, Emscripten, Microfrontend, Sass",
      industry: "Information Technology and Services",
      date: "2023",
      thumbImage: "images/projects/apple/apple-logo.png",
      categories: [filters.WEB, filters.BUSINESS],
    },
    {
      title: "Financial Services Investment App",
      projectInfo:
        "As frontend team lead my team and I developed a successful React Native investment app that built to a Web app, along with iOS and Android. It makes use of biometrics, KYC flows and advanced search along with portfolio management features.",
      client: "DWS / Deutsche Bank",
      technologies:
        "React Native, React, Android, iOS, Typescript, GraphQL-like, Biometics",
      industry: "Financial Services",
      date: "2018 - 2022",
      url: {
        name: "DWS Investment",
        link: "https://online.dws.de",
      },
      url2: {
        name: "iOS App Store",
        link: "https://apps.apple.com/sk/app/dws-altersvorsorge/id6444192420",
      },
      url3: {
        name: "Android Play Store",
        link: "https://play.google.com/store/apps/details?id=com.db.dws.pension&hl=en&gl=US",
      },
      socialLinks: {
        youtube: "https://www.youtube.com/watch?v=AYIl4v867Dw",
      },
      thumbImage: "images/projects/dws/dws1.png",
      sliderImages: [
        "images/projects/dws/dws2.png",
        "images/projects/dws/dws3.png",
        "images/projects/dws/dws4.png",
        "images/projects/dws/dws5.png",
      ],
      categories: [filters.WEB, filters.MOBILE, filters.BUSINESS],
    },
    {
      title: "E-Commerce Intelligence Web App",
      projectInfo:
        "I joined the Birdsview team in helping them enhance their React/Next.js app with features, developed a query builder for targeting customers with AI, streamlined UX, designed Supabase databases, deployed via Vercel & improved custom components.",
      client: "Birdsview",
      technologies: "NextJS, React, Typescript, Tailwind, Supabase",
      industry: "E-Commerce",
      date: "2023",
      url: {
        name: "Birdsview App",
        link: "https://www.birdsview.com/",
      },
      thumbImage: "images/projects/birdsview/birdsview1.png",
      sliderImages: ["images/projects/birdsview/birdsview2.png"],
      categories: [filters.WEB, filters.BUSINESS],
    },
    {
      title: "Robo Investment Advisor",
      projectInfo:
        "As a founding developer of the project, I helped develop Deutsche Asset Management's first Robo Investment Advisor (part of Deutsche Bank). It was a white label multi-tenancy solution of three interconnected projects - Profiling, Onboarding and a Dashboard. A rebrand to MorgenFund was completed as my involvement ended.",
      client: "Deutsche Bank / Deutsche AM / DWS (now rebranded to MorgenFund)",
      technologies: "React, Redux, JSX, HTML, CSS",
      industry: "Financial Services",
      date: "2016 - 2017 & 2022",
      url: {
        name: "Robo Advisor",
        link: "https://www.deutscheinvest.lu",
      },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/deutsche/deutsche1.png",
      sliderImages: [
        "images/projects/deutsche/deutsche2.png",
        "images/projects/deutsche/deutsche3.png",
        "images/projects/deutsche/deutsche4.png",
        "images/projects/deutsche/deutsche5.png",
      ],
      categories: [filters.WEB, filters.BUSINESS],
    },
    {
      title: "Funding Finder Platform",
      projectInfo:
        "I assisted in the creation of the Alternative Business Funding platform, a UK government initiative to help small businesses find funding. I was involved in the front and backend development of the web app.",
      client: "Clifton Asset Management",
      technologies: "PHP, Laravel, AngularJS, HTML, CSS, MySQL",
      industry: "Financial Services",
      date: "2015",
      url: {
        name: "Alternative Business Funding",
        link: "https://www.alternativebusinessfunding.co.uk/",
      },
      thumbImage: "images/projects/abf/abf1.png",
      sliderImages: ["images/projects/abf/abf2.png"],
      categories: [filters.WEB, filters.BUSINESS],
    },
    {
      title: "Multiplayer Game - Solitude",
      projectInfo:
        "Solitude is a 3D, first person perspective, space surival multiplayer game. I was the lead developer on the project, working on the architecture, gameplay, UI, server and mod support system.",
      client: "Rogue Vector",
      technologies: "C#, C++, Lua, Unity, CoherentUI",
      industry: "Games Industry",
      date: "2014 - 2024",
      thumbImage: "images/projects/solitude/solitude1.jpg",
      sliderImages: [
        "images/projects/solitude/solitude2.jpg",
        "images/projects/solitude/solitude3.jpg",
      ],
      categories: [filters.APPLICATION, filters.GAME],
    },
    {
      title: "Game Website - Solitude",
      projectInfo:
        "I created the website for the Solitude game, which was built using React. It was a single page application with Humble game purchase widget integration.",
      client: "Rogue Vector",
      technologies: "React, Typescript, Humble Widget, HTML, CSS",
      industry: "Games Industry",
      date: "2023",
      url: {
        name: "Solitude Website",
        link: "https://www.solitudegame.com/",
      },
      thumbImage: "images/projects/solitudesite/solitudesite1.png",
      sliderImages: [
        "images/projects/solitudesite/solitudesite2.png",
        "images/projects/solitudesite/solitudesite3.png",
        "images/projects/solitudesite/solitudesite4.png",
        "images/projects/solitudesite/solitudesite5.png",
      ],
      categories: [filters.WEB, filters.GAME],
    },
    {
      title: "Conversation Dialogue Editor",
      projectInfo:
        "This conversation dialogue editor is an open source project I created to aid players of the game 'BattleTech'. It allows them to create new, or edit existing, in-game dialogue that would otherwise be impossible to create without this tool.",
      client: "Open Source Project",
      technologies: "C#, Chromely, React, Typescript, MobX, HTML, CSS",
      industry: "Games Industry",
      date: "2018 - 2024",
      url: {
        name: "ConverseTek Github",
        link: "https://github.com/CWolfs/ConverseTek",
      },
      socialLinks: {
        youtube: "https://www.youtube.com/watch?v=GFBJt1Cjpwk",
      },
      thumbImage: "images/projects/conversetek/conversetek1.png",
      sliderImages: [
        "images/projects/conversetek/conversetek2.png",
        "images/projects/conversetek/conversetek3.png",
        "images/projects/conversetek/conversetek4.png",
      ],
      categories: [filters.APPLICATION, filters.GAME],
    },
    {
      title: "Game Mod - Mission Control",
      projectInfo:
        "This is a mod for the game 'BattleTech' that I created to allow players to customise their campaign experience. It allows them to add new missions, change the difficulty, expand the map size, and many other aspects of the game. It required reverse engineering the game 'BattleTech' and involved code injection / patching.",
      client: "Open Source Project",
      technologies: "C#, Unity",
      industry: "Games Industry",
      date: "2018 - 2024",
      url: {
        name: "Mission Control Github",
        link: "https://github.com/CWolfs/MissionControl",
      },
      socialLinks: {
        youtube: "https://www.youtube.com/watch?v=MGPrebHW0R0",
      },
      thumbImage: "images/projects/missioncontrol/missioncontrol1.jpg",
      sliderImages: [
        "images/projects/missioncontrol/missioncontrol2.png",
        "images/projects/missioncontrol/missioncontrol3.png",
        "images/projects/missioncontrol/missioncontrol4.png",
      ],
      categories: [filters.APPLICATION, filters.GAME],
    },
    {
      title: "Android Mobile Game",
      projectInfo:
        "I created the Android mobile game called 'Mechadroids'. It was a multiplayer menu based game that allowed players to battle each other with their own customised robots.",
      client: "Deadworld Studios",
      technologies: "PHP, Android Studio, Java",
      industry: "Games Industry",
      date: "2012",
      thumbImage: "images/projects/mechadroids/mechadroids1.png",
      sliderImages: [
        "images/projects/mechadroids/mechadroids2.png",
        "images/projects/mechadroids/mechadroids3.png",
        "images/projects/mechadroids/mechadroids4.png",
      ],
      categories: [filters.MOBILE, filters.GAME],
    },
    {
      title: "Game Mission Editor",
      projectInfo:
        "This is an open source mission editor for 'BattleTech' game. It allows players to create new missions for the game. It is built into the game itself and allows for easy visualisation of the maps the missions are based on.",
      client: "Open Source Project",
      technologies: "C#, Unity",
      industry: "Games Industry",
      date: "2021 - 2024",
      socialLinks: {
        youtube: "https://www.youtube.com/watch?v=xtYc59cSXeI",
      },
      thumbImage: "images/projects/mcd/mcd1.jpg",
      sliderImages: [
        "images/projects/mcd/mcd2.jpg",
        "images/projects/mcd/mcd3.png",
        "images/projects/mcd/mcd4.png",
        "images/projects/mcd/mcd5.png",
        "images/projects/mcd/mcd6.jpg",
      ],
      categories: [filters.APPLICATION, filters.GAME],
    },
    {
      title: "Engineering Product Sizer",
      projectInfo:
        "This is a web app I created for a client to help their customers size specific products against their own measurements and data. It was used for engineering focused products to be sized for specific applications.",
      client: "Hydro International",
      technologies: "PHP, Javascript, HTML, CSS",
      industry: "Engineering",
      date: "2015",
      thumbImage: "images/projects/downstreamdefender/dd1.png",
      sliderImages: [
        "images/projects/downstreamdefender/dd2.png",
        "images/projects/downstreamdefender/dd3.png",
      ],
      categories: [filters.WEB, filters.BUSINESS],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              My Work
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            aria-label="Example"
                            href="/"
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            <span className="text-light">
                              {project.categories.length > 1
                                ? project.categories.join(", ")
                                : project.categories}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
