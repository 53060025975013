import React from "react";
import { Link } from "react-scroll";

import resumeFile from "../documents/Richard_Griffiths_CV.pdf";

const AboutUs = ({ classicHeader, darkTheme }) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            About Me
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Let's Start...
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              I'm <span className="text-primary">Richard Griffiths</span>,
              <span className="d-block d-sm-inline"> a Lead Developer</span>
            </h2>
            <p className={darkTheme ? "text-white-50" : ""}>
              I love to design and build software. My journey from backend to
              frontend, through app & games development, has been incredibly
              rewarding. I've worked with some of the biggest names in the world
              and in my spare time freely contributed and shared with the open
              source community.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              I guide teams through complex projects, making a large positive
              impact and always strive to make projects successful!
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              Want to work together?{" "}
              <Link
                smooth
                duration={500}
                style={{ cursor: "pointer", display: "inline" }}
                spy
                activeClass="active"
                className="nav-link text-primary"
                to="contact"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Feel free to contact me.
              </Link>
            </p>
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                  <span className="fw-600 me-2">Name:</span>Richard Griffiths
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:richard@fractalrift.com">
                    richard@fractalrift.com
                  </a>
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">From:</span>Cardiff, United
                  Kingdom
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">Living In:</span>Frankfurt,
                  Germany
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">Degree:</span>BSc with Honours @
                  Cardiff University, UK
                </li>
              </ul>
              <div className="text-center text-md-start">
                <a
                  href={resumeFile}
                  download
                  className="btn btn-primary rounded-pill"
                >
                  Download CV
                </a>
              </div>
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-12 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>17</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Years Experiance
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>Apple & Deutsche Bank</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  World Class Clients
                </p>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>2</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Startups Founded
                </p>
              </div>
            </div>
            {/* <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>38</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Get Awards
                </p>
              </div>
            </div> */}
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
